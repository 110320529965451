// Generated by Framer (8d84d1c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["ezqpW7OZH", "SpM_5HQdy"];

const serializationHash = "framer-aImAU"

const variantClassNames = {ezqpW7OZH: "framer-v-php9aq", SpM_5HQdy: "framer-v-do2u5b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 2, ease: [0, 0, 1, 1], type: "tween"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "ezqpW7OZH", "Variant 2": "SpM_5HQdy"}

const getProps = ({height, id, transition, width, ...props}) => { return {...props, uiHdaVAV1: transition ?? props.uiHdaVAV1 ?? {damping: 100, delay: 0, duration: 1, ease: [0.5, 0, 0.88, 0.77], mass: 11.1, stiffness: 492, type: "spring"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ezqpW7OZH"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;transition?: Record<string, any>; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, uiHdaVAV1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ezqpW7OZH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1mgwvt2 = activeVariantCallback(async (...args) => {
setVariant("SpM_5HQdy")
})

const onAppeartsujga = activeVariantCallback(async (...args) => {
setVariant("ezqpW7OZH")
})

useOnVariantChange(baseVariant, {default: onAppear1mgwvt2, SpM_5HQdy: onAppeartsujga})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={uiHdaVAV1}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"mirror"} __framer__loopTransition={transition1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-php9aq", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ezqpW7OZH"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 42, 0)", borderBottomLeftRadius: 475, borderBottomRightRadius: 475, borderTopLeftRadius: 475, borderTopRightRadius: 475, boxShadow: "0px 0px 20px 5px rgba(255, 42, 0, 0.4)", ...style}} {...addPropertyOverrides({SpM_5HQdy: {__framer__loopTransition: transition2, "data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7S3VtYmggU2Fucy1yZWd1bGFy", "--framer-font-family": "\"Kumbh Sans\", \"Kumbh Sans Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-letter-spacing": "0.54em", "--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>SUMMIT ESGOTADO</motion.p></React.Fragment>} className={"framer-p8cy6p"} fonts={["GF;Kumbh Sans-regular"]} layoutDependency={layoutDependency} layoutId={"Q6htYHlGG"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aImAU.framer-1cgh1mt, .framer-aImAU .framer-1cgh1mt { display: block; }", ".framer-aImAU.framer-php9aq { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 30px; justify-content: center; overflow: hidden; padding: 8px 16px 8px 16px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-aImAU .framer-p8cy6p { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-aImAU.framer-php9aq { gap: 0px; } .framer-aImAU.framer-php9aq > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-aImAU.framer-php9aq > :first-child { margin-left: 0px; } .framer-aImAU.framer-php9aq > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 30
 * @framerIntrinsicWidth 266
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"SpM_5HQdy":{"layout":["auto","fixed"]}}}
 * @framerVariables {"uiHdaVAV1":"transition"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCWZJGhd8V: React.ComponentType<Props> = withCSS(Component, css, "framer-aImAU") as typeof Component;
export default FramerCWZJGhd8V;

FramerCWZJGhd8V.displayName = "Button Summit";

FramerCWZJGhd8V.defaultProps = {height: 30, width: 266};

addPropertyControls(FramerCWZJGhd8V, {variant: {options: ["ezqpW7OZH", "SpM_5HQdy"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, uiHdaVAV1: {defaultValue: {damping: 100, delay: 0, duration: 1, ease: [0.5, 0, 0.88, 0.77], mass: 11.1, stiffness: 492, type: "spring"}, title: "Transition", type: ControlType.Transition}} as any)

addFonts(FramerCWZJGhd8V, [{explicitInter: true, fonts: [{family: "Kumbh Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQkZYA9LTuUkqaLg.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})